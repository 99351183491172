<template>
  <Popup popupType="tableType" width="744px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">프로젝트 코드 조회</h3>
      </div>
      <div class="body_section">
        <ProjectCodePopupFilterBar @onClickSearch="onClickSearch" />

        <VertualScroller :dataList="dataList" :keyField="'projectCode'" :height="'400px'">
          <template v-slot:header>
            <div class="vertual-header">
              <div class="vertual-header__cell"></div>
              <div class="vertual-header__cell">프로젝트 코드</div>
              <div class="vertual-header__cell">사업명</div>
            </div>
          </template>
          <template v-slot:slotItem="{ slotProps }">
            <ProjectCodePopupListLineVertual
              :rowData="slotProps"
              :class="{
                'vertual-row--select':
                  itemSelectedCode && itemSelectedCode === slotProps.projectCode,
              }"
              :selectedProjectCode.sync="itemSelectedCode"
              :selectedProjectObj.sync="itemSelectedObj"
            />
          </template>
        </VertualScroller>
        <!-- <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 744px)"
          scrollMaxHeight="294px"
          scrollMinHeight="49px"
          :showPagination="false"
        >
          <template v-slot:colgroup>
            <col style="width: 52px" />
            <col style="width: 184px" />
            <col />
          </template>
          <template v-slot:tr>
            <th style="width: 52px" />
            <th style="width: 184px">프로젝트코드</th>
            <th style="width: 484px">사업명</th>
          </template>
          <template v-slot:rows>
            <template v-for="(item, index) in dataList">
              <ProjectCodePopupListLine
                :key="`${item.projectCode}_${index}`"
                :class="{ tr_select: itemSelectedCode && itemSelectedCode === item.projectCode }"
                :rowData="item"
                :selectedProjectCode.sync="itemSelectedCode"
                :selectedProjectObj.sync="itemSelectedObj"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="4" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board> -->
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_medium btn_fourthly" @click.prevent="$emit('onClickClose')">
        취소
      </button>
      <button
        type="button"
        class="btn_medium btn_medium btn_secondary"
        :disabled="!itemSelectedCode"
        @click.prevent="
          [$emit('onClickComplete', itemSelectedCode), $emit('onClickCompleteObj', itemSelectedObj)]
        "
      >
        적용
      </button>
    </div>
  </Popup>
</template>
<script>
import { mapState } from "vuex";

import Popup from "@/components/layout/popup/Popup";
import Board from "@/components/common/board/Board";
import VertualScroller from "@/components/common/vertualScroller/VertualScroller";

import ProjectCodePopupFilterBar from "./ProjectCodePopupFilterBar";

import ProjectCodePopupListLine from "./ProjectCodePopupListLine";
import ProjectCodePopupListLineVertual from "./ProjectCodePopupListLineVertual";

import ApiService from "@/services/ApiService";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

export default {
  name: "ProjectCodePopup",
  components: {
    Popup,
    VertualScroller,
    ProjectCodePopupFilterBar,
    ProjectCodePopupListLine,
    Board,

    ProjectCodePopupListLineVertual,
  },
  props: {
    selectedProjectCode: String,
  },
  data() {
    return {
      // PROJECT_CODE
      dataList: [],
      itemSelectedCode: "",
      itemSelectedObj: {
        projectCode: "",
        projectName: "",
      },
    };
  },
  computed: {
    ...mapState({
      projectList: (state) => state.projectList.projectList,
    }),
    dataListSync: {
      get() {
        return this.projectList;
      },
      set(value) {
        this.dataList = _.cloneDeep(value);
      },
    },
  },
  created() {
    this.itemSelectedCode = this.selectedProjectCode;
    const path = `${this.$apiPath.PROJECT_CODE}`;
    this.dataListSync = this.projectList;
    //this.getData(path);
  },
  methods: {
    async getData(path) {
      await this.$store.dispatch(PROGRESS_START_ACTION);
      const result = await ApiService.shared.getData(path);

      await this.$store.dispatch(PROGRESS_END_ACTION);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }
      if (result.data && result.data.length > 0) {
        this.dataListSync = result.data;
      } else {
        this.dataListSync = [];
      }
    },
    onClickSearch(keywordInputText) {
      const path = `${this.$apiPath.PROJECT_CODE}?projectName=${encodeURIComponent(
        keywordInputText,
      )}`;
      this.getData(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.vertual {
  &-header {
    &__cell {
      text-align: center;
      &:nth-child(1) {
        width: 52px;
      }
      &:nth-child(2) {
        width: 184px;
      }
      &:nth-child(3) {
      }
    }
  }
}
</style>
