import { getMoneyToNumber } from "@/utils/stringUtils";

export default class PurchasePriceListModel {
  constructor(obj) {
    this.objRaw = {};

    this.objItemData = {};
    this.note = "";
    this.requestPrice = ""; // 기안금액
    this.draftingPrice = "";
    this.projectCode = ""; // 프로젝트코드
    this.projectName = ""; // 프로젝트명
    this.assetsCode = ""; // 프로젝트명

    this.unitType = "E"; //단위
    this.unitValue = "EA"; //단위

    this.addPrice = ""; // 추가 금액

    if (obj) {
      this.setData(obj);
    }
  }

  setItemData(obj) {
    this.objItemData = obj;

    const { requestPrice, projectCode, projectName, addPrice, unitType, unitValue } = obj;

    this.requestPrice = requestPrice || requestPrice === 0 ? String(requestPrice) : "";
    this.draftingPrice = requestPrice || requestPrice === 0 ? String(requestPrice) : "";
    this.projectCode = projectCode || "";
    this.projectName = projectName || "";
    this.addPrice = addPrice + "" || "0";
    this.unitType = unitType + "" || "E";
    this.unitValue = unitValue + "" || "EA";
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      requestPrice,
      projectCode,
      projectName,
      addPrice,
      note,
      assetsCode,
      unitType,
      unitValue,
    } = obj;

    this.requestPrice = requestPrice || requestPrice === 0 ? String(requestPrice) : "";
    this.draftingPrice = requestPrice || requestPrice === 0 ? String(requestPrice) : "";
    this.projectCode = projectCode || "";
    this.projectName = projectName || "";
    this.addPrice = addPrice + "" || "0";
    this.assetsCode = assetsCode || "";
    this.note = note || "";
    this.unitType = unitType + "" || "E";
    this.unitValue = unitValue + "" || "EA";
  }

  getData() {
    let obj = {
      draftingPrice: getMoneyToNumber(this.draftingPrice),
      requestPrice: getMoneyToNumber(this.requestPrice),
      projectCode: this.projectCode,
      projectName: this.projectName,
      assetsCode: this.assetsCode,
      noth: this.note,
      unitType: this.unitType,
      unitValue: this.unitValue,
    };

    return Object.assign({}, this.objItemData, this.objRaw, obj);
  }

  getAlertMessage() {
    // if (this.requestPrice === 0 || this.requestPrice === '0' || !this.requestPrice)
    //   return '품목의 수량을 확인해주세요.';
    // if (this.unitPrice !== 0 && !this.unitPrice)
    //   return '품목의 단가를 확인해주세요.';

    return null;
  }

  isEmpty() {
    return !this.requestPrice || !this.projectCode;
  }
}
