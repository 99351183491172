<!--
{ id: '', code: '', desc: '전체' },
{ id: 'N', code: 'N', desc: '접수대기' },
{ id: 'C', code: 'C', desc: '반려' },
{ id: 'M', code: 'M', desc: '재고지급' },
{ id: 'S', code: 'S', desc: '접수완료' },
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">
          구매요청 <span class="txt_view">{{ model.requestCid }}</span>
        </h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="구매요청 내용">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 428px" />
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <tr>
              <th>
                프로젝트 코드
                <span class="ico_purchase ico_star">필수항목</span>
              </th>
              <td>
                <div class="group_form">
                  <Input :value.sync="model.projectCode" :isDisabled="true" />
                  <button class="btn_search" @click="onClickProjectCodePopup">
                    <span class="ico_purchase ico_search" />
                  </button>
                </div>
              </td>
              <th>
                프로젝트 명
                <span class="ico_purchase ico_star">필수항목</span>
              </th>
              <td>
                <Input :value.sync="model.projectName" :isDisabled="true" />
              </td>
            </tr>
            <tr>
              <th>
                구매요청명
                <span class="ico_purchase ico_star">필수항목</span>
              </th>
              <td colspan="3">
                <Input :value.sync="model.title" placeholder="구매요청명을 입력하세요" />
              </td>
            </tr>
            <tr>
              <th>
                내용
                <span class="ico_purchase ico_star">필수항목</span><br/><br/>
                ※  필수기재사항<br/>
              - 용역계약의 경우<br/>&nbsp;&nbsp;턴키/MM 구분 정보<br/>
              - 요청사항 / 매입계약시 유의사항<br/>
              - 납기요청일 / 보증보험증권 요율<br/>
              - 기타 특기사항 등 구체적 내용
              </th>
              <td class="td_text" style="height: 200px" colspan="3">
                <Textarea
                  :value.sync="model.contents"
                  placeholder="내용을 입력하세요"
                  :maxLength="300"
                />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>

        <TableViewWithTitle title="구매구분">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <th>
              구매구분
              <span class="ico_purchase ico_star">필수항목</span>
            </th>
            <td>
              <SelectBox
                :dataList="model.assetTypeList"
                :value.sync="model.assetType"
                @onChange="onChangeResetPurchaseList"
              />
            </td>
          </template>
        </TableViewWithTitle>

        <!-- 일반구매 금액 -->
        <!-- <PurchasePriceNormal
          v-if="model.assetType === 'H'"
          ref="PurchasePriceNormal"
          :model.sync="model"
          @onClickSearchProjectCode="onClickProjectCodePopup"
        /> -->
        <!-- 자산구매 금액 -->
        <PurchasePriceInfra
          ref="PurchasePriceInfra"
          :model.sync="model"
          :assetsCode="model.assetType == 'I' ? true : false"
          @onClickSearchGoodsName="onClickSearchGoodsName"
        />
        <TableViewWithTitle title="구매요청 정보">
          <template v-slot:body>
            <tr>
              <th>진행상태</th>
              <td colspan="3">
                {{ model.statusCodeName }}
              </td>
            </tr>
            <tr>
              <th>구매 요청번호</th>
              <td>{{ model.requestCid }}</td>
              <th>구매 요청등록일</th>
              <td>{{ model.requestDate | dateStringFormat }}</td>
            </tr>
            <tr>
              <th>
                구매요청자
                <span class="ico_purchase ico_star">필수항목</span>
              </th>
              <td class="td_ldap">
                {{ `${model.requesterName} (${model.requester})` }}
              </td>
              <th>요청부서</th>
              <td>{{ model.requestDeptName }}</td>
            </tr>
            <tr>
              <th>
                구매담당자
                <span class="ico_purchase ico_star">필수항목</span>
              </th>
              <td colspan="3">
                <div class="">
                  <ManagerLdap
                    :dataList.sync="model.buyerList"
                    :isCanEdit="false"
                    :isEditMode.sync="isEditMode"
                    :isFromPurchase="true"
                    @onClickRemoveTag="onClickRemoveTag"
                    @onClickPopLdapMultiple="onClickPopLdapMultiple"
                    @onClickPopLdapChange="onClickPopLdapChange"
                    @alert="alert"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>계약 기간</th>
              <td colspan="3">
                <DateRange :dates.sync="model.dates" />
              </td>
            </tr>
            <tr>
              <th>대금지급 방법 및 조건</th>
              <td colspan="3">
                <SelectBox :dataList="model.paymentList" :value.sync="model.payment" />
              </td>
            </tr>
            <tr>
              <th>선금비율</th>
              <td>
                <div class="group_form">
                  <InputRate
                    class="w90"
                    :maxLength="5"
                    :isDisabled="model.payment == 'R' ? true : false"
                    :maxValue="100"
                    :value.sync="model.advanceRate"
                  />
                  <div class="txt_view_r">%</div>
                </div>
              </td>

              <th>선금</th>
              <td>
                <InputAutoMoney
                  :maxLength="14"
                  :value.sync="model.advance"
                  :placeholder="'선금'"
                  :isDisabled="true"
                />
              </td>
            </tr>
            <tr>
              <th>중도금비율</th>
              <td>
                <div class="group_form">
                  <InputRate
                    class="w90"
                    :maxLength="5"
                    :isDisabled="model.payment == 'R' ? true : false"
                    :maxValue="100"
                    :value.sync="model.interimRate"
                  />
                  <div class="txt_view_r">%</div>
                </div>
              </td>

              <th>중도금</th>
              <td>
                <InputAutoMoney
                  :maxLength="14"
                  :value.sync="model.interim"
                  :placeholder="'중도금'"
                  :isDisabled="true"
                />
              </td>
            </tr>
            <tr>
              <th>잔금비율</th>
              <td>
                <div class="group_form">
                  <InputRate
                    class="w90"
                    :isDisabled="true"
                    :maxLength="6"
                    :maxValue="100"
                    :value.sync="model.balanceRate"
                  />
                  <div class="txt_view_r">%</div>
                </div>
              </td>
              <th>잔금</th>
              <td>
                <InputAutoMoney
                  :maxLength="14"
                  :value.sync="model.balance"
                  :placeholder="'잔금'"
                  :isDisabled="true"
                />
              </td>
            </tr>
            <tr>
              <th>첨부파일</th>
              <td colspan="3">
                <FileWrite
                  :dataList="model.attachFileList"
                  @fileUploaded="fileUploaded"
                  @fileRemoved="fileRemoved"
                /><br/>
              ※  필수첨부서류<br/>
              - 계약/수정 CP 전자결재 품의서<br/>
              - 계약/수정 CP 파일<br/>
              - 견적서<br/>
              - 턴키계약의 경우 수행계획서<br/>
              - 계약 참고서류 (공문 등) 
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button
          v-if="!isModifyMode"
          type="button"
          class="btn_primary btn_large"
          @click="onClickSave"
        >
          등록
        </button>
        <button v-else type="button" class="btn_primary btn_large" @click="onClickUpdate">
          수정완료
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isSaveConfirm"
        alertText="요청 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="savePurchase"
      />
      <AlertPopup
        v-if="isUpdateConfirm"
        alertText="수정 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="updatePurchase"
      />
      <LdapPopup
        v-if="isPopLdapRequester"
        @onClickSelect="onClickSelect"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
      <LdapMultiplePopup
        v-if="isPopLdapMultiple"
        :isManager="'Y'"
        :selectedDataList="model.buyerList"
        :disabledDataList="disabledDataList"
        @onClickSelect="onClickSelectLdapMultiple"
        @onClickClose="onClickClosePopLdapManager"
        @alert="alert"
      />
      <ProjectCodePopup
        v-if="isPopProjectCode"
        :selectedProjectCode="model.projectCode"
        @onClickClose="onClickCloseProjectCodePopup"
        @onClickCompleteObj="onClickCompleteProjectCodePopup"
      />
      <!--@onClickComplete="onClickCompleteProjectCodePopup"-->

      <PopGoodsNameSearch
        v-if="isPopGoodsNameSearch"
        :selectedGoodsName="selectedGoodsName"
        @onClickClose="onClickClosePopGoodsNameSearch"
        @onClickComplete="onClickCompletePopGoodsNameSearch"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import TableHead from "@/components/shared/TableHead";
import FileWrite from "@/components/common/file/FileWrite";
import Textarea from "@/components/common/textarea/Textarea";
import DateRange from "@/components/common/calendar/DateRange";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";
import InputRate from "@/components/common/input/InputRate";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import SelectBox from "@/components/common/selectBox/SelectBox";

import ManagerLdap from "@/components/shared/managerLdap/ManagerLdap";
import Sticky from "@/components/layout/content/Sticky";
import LdapPopup from "@/components/layout/popup/ldapPopup/LdapPopup";
import LdapMultiplePopup from "@/components/layout/popup/ldapPopup/LdapMultiplePopup";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import PageMixin from "@/mixins/PageMixin";
import ManagerLdapMixin from "@/mixins/ManagerLdapMixin";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { getContractDept, escapeRegExp } from "@/utils/spUtils";

import PurchasePriceNormal from "@/components/admin/purchase/write/PurchasePriceNormal";
import PurchasePriceInfra from "@/components/admin/purchase/write/PurchasePriceInfra";
import ProjectCodePopup from "@/components/admin/order/popup/ProjectCodePopup.vue";
import PopGoodsNameSearch from "@/components/admin/draft/write/popup/PopGoodsNameSearch.vue";
import { addCommaForMoney, getMoneyToNumber, getMoneyToKorean } from "@/utils/stringUtils";
import purchaseModel from "./purchaseModel";

import PurchasePriceListModel from "@/components/admin/purchase/write/PurchasePriceListModel";
import PurchaseGoodsListModel from "@/components/admin/purchase/write/PurchaseGoodsListModel";

import { makePathWithQuery } from "@/utils/urlUtils";

import { GET_PROJECTLIST_ACTION } from "@LIG/store/modules/projectList/action";

export default {
  name: "PurchaseWrite",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    TableHead,
    FileWrite,
    InputAutoMoney,
    Textarea,
    Radio,
    Input,
    InputRate,
    DateRange,
    SelectBox,

    ManagerLdap,
    Sticky,
    LdapPopup,
    LdapMultiplePopup,
    AlertPopup,
    PurchasePriceNormal,
    PurchasePriceInfra,

    ProjectCodePopup,
    PopGoodsNameSearch,
  },
  mixins: [PageMixin, ManagerLdapMixin],
  data() {
    return {
      isModifyMode: false,
      isSaveConfirm: false,
      isUpdateConfirm: false,
      model: new purchaseModel(),
      selectedGoodsName: {},
      productInfo: {
        goods: "",
        amount: "",
        currency: "KRW",
        quantity: "EA",
        budget: "",
      },
      isPopLdapRequester: false,
      isPopProjectCode: false,
      isPopGoodsNameSearch: false,

      projectCodeIndex: null,
      goodsNameTargetIndex: null,
    };
  },
  computed: {
    contents() {
      if (!this.model.contents) return;
      let target = this.model.contents;
      return escapeRegExp(target);
    },
    managerLdapDataList: {
      get() {
        return this.model.buyerList;
      },
      set(value) {
        this.model.buyerList = value;
      },
    },
  },
  watch: {
    "model.advanceRate": function (newVal, oldVal) {
      const { balanceRate, interimRate } = this.model;
      const finalPrice = this.model.totalPrice;
      const calcRate = 100 - (Number(interimRate) + Number(newVal));
      const calcDownPayment = (getMoneyToNumber(finalPrice) / 100) * newVal;
      this.model.balanceRate = String(calcRate); //잔금 비율
      this.model.advance = String(_.round(calcDownPayment, 2)); //계약금 지급금액
      const calcBalance =
        getMoneyToNumber(finalPrice) - (calcDownPayment + getMoneyToNumber(this.model.interim));
      this.model.balance = addCommaForMoney(calcBalance); // 잔금 지급금액(계약금액 - (계약금 + 중도금))
    },
    "model.interimRate": function (newVal, oldVal) {
      const { advanceRate } = this.model;
      const finalPrice = this.model.totalPrice;
      const calcRate = 100 - (Number(advanceRate) + Number(newVal));
      const calcDownMiddlePayment = (getMoneyToNumber(finalPrice) / 100) * newVal;
      this.model.balanceRate = String(calcRate); //잔금 비율
      this.model.interim = String(_.round(calcDownMiddlePayment)); //중도금 지급금액
      const calcBalance =
        getMoneyToNumber(finalPrice) -
        (calcDownMiddlePayment + getMoneyToNumber(this.model.advance));
      this.model.balance = addCommaForMoney(calcBalance); // 잔금 지급금액(계약금액 - (중도금 + 계약금))
    },
  },
  async created() {
    await this.$store.dispatch(GET_PROJECTLIST_ACTION);

    const { params, meta } = this.$route;
    if (params.type === "renewal") {
      this.getData(params.requestCid);
    } else {
      this.model.requestCid = params.id;
      this.isModifyMode = meta.isModifyMode;
      if (this.isModifyMode) {
        //api call
        this.getData(params.id);
      }
      if (this.model.requester) {
        // 요청자 정보 가져오기
        this.getDeptName(this.model.requester);
        // 담당자 정보 가져오기
        this.getDefaultManager();
      }
    }
  },
  beforeMount() {
    this.model.purchasePriceList = [new PurchasePriceListModel()];
    this.model.purchaseGoodsList = [new PurchaseGoodsListModel()];
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isModifyMode && vm.model.status !== "N") {
        alert("잘못된 접근입니다.");
        vm.$router.push({
          path: vm.$routerPath.PURCHASE_LIST,
        });
      }
    });
  },
  methods: {
    async getData(id) {
      const path = `${this.$apiPath.ST_PURCHASE_REQUEST}/${id}`;

      const result = await ApiService.shared.getData(path);

      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.model.setData(data);
      const { params, meta } = this.$route;
      if (params.type === "renewal") {
        const userData = LocalStorageManager.shared.getUserData();

        this.model.requesterEmpNo = userData.empNo;
        this.model.requester = userData.username;
        this.model.requesterName = userData.name;
        this.model.requestDeptName = userData.deptName;
        this.model.requestDate = "-";
      }
    },
    onClickPopLdapRequester() {
      this.isPopLdapRequester = true;
    },
    onClickRemoveRequester() {
      const emptyObj = { accountId: "", personName: "", deptPathName: "" };
      this.model.setLdapData(emptyObj);
    },
    onClickClosePopLdap() {
      this.isPopLdapRequester = false;
    },
    onClickSelect(rowData) {
      this.model.setLdapData(rowData);
      this.isPopLdapRequester = false;
    },
    fileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },
    onClickGoToList() {
      this.goToList();
    },
    goToList() {
      this.$router.push(this.$routerPath.PURCHASE_LIST);
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.PURCHASE_MODIFY}/${this.requestCid}`,
      });
    },
    onClickSave() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      this.isSaveConfirm = true;
    },
    onClickUpdate() {
      this.isUpdateConfirm = true;
    },
    async savePurchase() {
      // requestStatus 값에 대기 : N, 반려 : C , 재고지급 : M, 완료 : S

      const obj = this.model.getData();
      const path = `${this.$apiPath.ST_PURCHASE_REQUEST}`;

      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }
      this.isSaveConfirm = false;

      const { requestCid } = result.data;

      this.$router.push({
        path: `${this.$routerPath.PURCHASE_VIEW}/${requestCid}`,
      });
    },
    async updatePurchase() {
      // requestStatus 값에 대기 : N, 반려 : C , 재고지급 : M, 완료 : S
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        this.isUpdateConfirm = false;
        return;
      }
      const obj = this.model.getData();

      const path = `${this.$apiPath.ST_PURCHASE_REQUEST_UPDATE}/${this.model.requestCid}`;
      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }
      this.isUpdateConfirm = false;
      const { requestCid } = result.data;

      this.$router.push({
        path: `${this.$routerPath.PURCHASE_VIEW}/${requestCid}`,
      });
    },
    onClickCloseAlertComfirm() {
      this.isSaveConfirm = false;
      this.isUpdateConfirm = false;
    },
    onClickSearchGoodsName(index) {
      this.isPopGoodsNameSearch = true;
      this.goodsNameTargetIndex = index;
    },
    //프로젝트 코드추가
    onClickProjectCodePopup() {
      this.isPopProjectCode = true;
    },
    onClickCloseProjectCodePopup() {
      this.isPopProjectCode = false;
    },
    onClickCompleteProjectCodePopup(selectedProject) {
      this.isPopProjectCode = false;

      this.model.projectCode = `${selectedProject.projectCode}`;
      this.model.projectName = `${_.unescape(selectedProject.projectName)}`;
      this.model.title = `[${_.unescape(selectedProject.projectName)}] `;
    },

    onChangeResetPurchaseList() {
      this.model.resetPurchaseList();
    },
    onChangeResetPaymentList() {
      this.model.resetPaymentList();
    },
    // 품명 조회 팝업 닫기
    onClickClosePopGoodsNameSearch() {
      this.isPopGoodsNameSearch = false;
      this.goodsNameTargetIndex = null;
      this.selectedGoodsName = {};
    },
    // 품명 조회 팝업 선택완료
    onClickCompletePopGoodsNameSearch(selectedGoodsName) {
      this.isPopGoodsNameSearch = false;
      const purchaseGoodsItem = this.model.purchaseGoodsList[this.goodsNameTargetIndex];
      purchaseGoodsItem.setData(selectedGoodsName);
    },
    async getDeptName(text) {
      // 구매담당자 - 요청부서 자동입력
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${text}&isManager=N`;
      const result = await ApiService.shared.getData(path);

      if (result.data && result.data.length > 0) {
        this.onClickSelect(result.data[0]);
      }
    },
    async getDefaultManager() {
      // 구매담당자 -  자동입력
      const path = `${this.$apiPath.MANAGER_OWNER}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      const { email, loginId, name } = data;
      const managerObj = {
        email: email,
        loginId: loginId,
        name: name,
      };
      this.onClickSelectLdapMultiple([managerObj]);
    },
  },
};
</script>
<style lang="scss" scoped>
.td_ldap .btn_tag_ldap {
  margin: 2px 0 0 12px;
}
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
