<template>
  <TableViewWithTitle title="구매요청금액" :tableViewClass="'tbl_view_sm12'">
    <template v-slot:colgroup>
      <!-- No -->
      <col style="width: 40px" />
      <!-- 품명 -->
      <col />
      <!-- 품목코드 -->
      <col style="width: 110px" />
      <!-- 자산코드 -->
      <col style="width: 110px" />
      <!-- 수량 -->
      <col style="width: 160px" />
      <!-- 단가 -->
      <col style="width: 92px" />
      <!-- 금액 -->
      <col style="width: 92px" />
      <!-- 단위 -->
      <col style="width: 164px" />
      <!-- 품목상세 -->
      <col style="width: 480px" />
      <!-- 추가/삭제 -->
      <col style="width: 86px" />
    </template>
    <template v-slot:header>
      <th scope="col">No.</th>
      <th scope="col">품명</th>
      <th scope="col">품목코드</th>
      <th scope="col">자산코드</th>
      <th scope="col">단위</th>
      <th scope="col">수량</th>
      <th scope="col">단가</th>
      <th scope="col">금액</th>
      <th scope="col">품목상세</th>
      <th scope="col">
        <button class="btn_small btn_secondary" @click="onClickAddGood">추가</button>
      </th>
    </template>
    <template v-slot:body>
      <PurchasePriceInfraListLine
        v-for="(item, index) in model.purchaseGoodsList"
        :key="index"
        :model="model"
        :purchasePriceItem="model.purchasePriceList[0]"
        :index="index"
        :item="item"
        :assetsCode="assetsCode"
        :isDisabledRemove="model.purchaseGoodsList.length === 1"
        @onClickSearchGoodsName="$emit('onClickSearchGoodsName', index)"
        @onClickRemove="onClickRemoveGood(item)"
        @updatePrice="updatePrice"
      />
      <!--         @onClickSearchProjectCode="$emit('onClickSearchProjectCode', 0)" -->
    </template>
    <template v-slot:foot>
      <div class="tbl_foot">
        <div class="area_total">
          <dl>
            <dt>총 기안금액</dt>
            <dd>{{ priceTotal | currency }}</dd>
          </dl>
        </div>
      </div>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import SelectBox from "@/components/common/selectBox/SelectBox";

import PurchasePriceInfraListLine from "./PurchasePriceInfraListLine";

export default {
  name: "PurchasePriceInfra",
  components: {
    TableViewWithTitle,
    PurchasePriceInfraListLine,
  },
  props: {
    model: Object,
    assetsCode: Boolean,
  },
  data() {
    return {
      currentList: [
        { code: "E", name: "EA", desc: "EA" },
        { code: "C", name: "Copy", desc: "Copy" },
        { code: "S", name: "식", desc: "Set" },
        { code: "T", name: "직접입력", desc: "직접입력" },
      ],
      currentSelected: { code: "E" },
    };
  },
  computed: {
    priceTotal() {
      return this.model.totalGoodsPrice();
      // const { purchaseGoodsList } = this.model;

      // const reducer = (accumulator, item) => {
      //   const price = getMoneyToNumber(item.price);

      //   return accumulator + price;
      // };

      // return this.model.reduce(reducer, 0);
    },
    isDirectMode() {
      return this.currentSelected.code == "T";
    },
  },

  methods: {
    onClickAddGood() {
      this.model.addGood();
    },
    onClickRemoveGood(itemToRemove) {
      this.model.removeGood(itemToRemove);
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    selectCurrent(selectedCurrentObj) {
      this.currentSelected = selectedCurrentObj;
    },
    updatePrice(index) {
      this.model.updatePrice(index);
    },
  },
};
</script>
