<template>
  <div class="vertual-row">
    <span class="vertual-row__cell">
      <RadioItem
        :id="rowData.projectCode"
        name="ProjectCodeSelect"
        :selectedId.sync="selectedProjectCodeSync"
      />
    </span>
    <span class="vertual-row__cell">{{ rowData.projectCode }}</span>
    <span class="vertual-row__cell" v-html="computedProjectName"></span>
  </div>
</template>

<script>
import RadioItem from "@/components/common/radio/RadioItem";
import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  name: "ProjectCodePopupListLineVertual",
  components: {
    RadioItem,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
    selectedProjectCode: String,
    selectedProjectObj: Object,
  },
  computed: {
    value() {
      let obj = {};
      obj.projectCode = this.rowData.projectCode;
      obj.projectName = this.rowData.projectName;
      return obj;
    },
    selectedProjectCodeSync: {
      get() {
        return this.selectedProjectCode;
      },
      set(value) {
        this.$emit("update:selectedProjectCode", Object.freeze(value));
        this.$emit("update:selectedProjectObj", {
          projectCode: this.rowData.projectCode,
          projectName: this.rowData.projectName,
        });
      },
    },
    computedProjectName() {
      return _.unescape(this.rowData.projectName);
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.vertual-row__cell {
  &:nth-child(1) {
    width: 52px;
    text-align: center;
    flex: 0 0 auto;
  }
  &:nth-child(2) {
    width: 184px;
    flex: 0 0 auto;
  }
  &:nth-child(3) {
    flex: 1 1 auto;
  }
}
</style>
