<template>
  <tr>
    <td>
      <RadioItem
        :id="rowData.projectCode"
        name="ProjectCodeSelect"
        :selectedId.sync="selectedProjectCodeSync"
      />
    </td>
    <td class="align_left">{{ rowData.projectCode }}</td>
    <td class="align_left" v-html="rowData.projectName"></td>
  </tr>
</template>

<script>
import RadioItem from "@/components/common/radio/RadioItem";
import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  name: "ProjectCodePopupListLine",
  components: {
    RadioItem,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
    selectedProjectCode: String,
    selectedProjectObj: Object,
  },
  computed: {
    value() {
      let obj = {};
      obj.projectCode = this.rowData.projectCode;
      obj.projectName = this.rowData.projectName;
      return obj;
    },
    selectedProjectCodeSync: {
      get() {
        return this.selectedProjectCode;
      },
      set(value) {
        this.$emit("update:selectedProjectCode", value);
        this.$emit("update:selectedProjectObj", {
          projectCode: this.rowData.projectCode,
          projectName: this.rowData.projectName,
        });
      },
    },
  },
};
</script>
