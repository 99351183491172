<template>
  <CalendarBase :class="{ 'is-active': isFocus }" :toolTip="toolTip">
    <el-date-picker
      v-model="input"
      type="daterange"
      :range-separator="separator"
      :start-placeholder="placeholderStart"
      :end-placeholder="placeholderEnd"
      :disabled="disabled"
      @focus="onFocus"
      @blur="onBlur"
    />
    <span class="ico_purchase ico_calendar" />
    <span class="ico_purchase ico_calendar" />
  </CalendarBase>
</template>

<script>
import CalendarBase from "./CalendarBase";

export default {
  name: "DateRange",
  components: {
    CalendarBase,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholderStart: {
      type: String,
      default: "시작일",
    },
    placeholderEnd: {
      type: String,
      default: "종료일",
    },
    separator: {
      type: String,
      default: "~",
    },
    toolTip: {
      type: String,
      default: "YYYY-MM-DD 형식으로 입력해주세요.",
    },
    dates: Array,
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    input: {
      get() {
        return this.dates;
      },
      set(value) {
        this.$emit("update:dates", value);
      },
    },
  },
  methods: {
    onFocus(e) {
      if (this.toolTip) {
        this.isFocus = true;
      }
    },
    onBlur() {
      this.isFocus = false;
    },
  },
};
</script>
