import LocalStorageManager from "@/LocalStorageManager";
import { draftConst } from "@/constants/const";
import PurchasePriceListModel from "@/components/admin/purchase/write/PurchasePriceListModel";
import PurchaseGoodsListModel from "@/components/admin/purchase/write/PurchaseGoodsListModel";
import { stringToDate, dateToString } from "@/utils/dateUtils";
import {
  addCommaForMoney,
  decodeHTMLEntities,
  getIsValidHtml,
  getMoneyToNumber,
} from "@/utils/stringUtils";
import { escapeRegExp } from "@/utils/spUtils";
import { GET_TEMPLATE_GUBUN_LIST } from "../../../store/modules/template/action";

export default class purchaseModel {
  constructor() {
    this.objRaw = {};
    this.userData = LocalStorageManager.shared.getUserData();
    this.requestCid = "-"; // 문서번호
    this.regId = ""; //로그인해서 쓴사용자
    this.status = ""; // 상태코드 (N:접수대기 / C:반려 / M:재고지급 / S:접수완료)
    this.statusCodeName = "-"; //(N:접수대기 / C:반려 / M:재고지급 / S:접수완료)
    this.title = ""; // 구매요청명
    this.contents = ""; // 내용
    this.requester = this.userData.username; //구매요청자
    this.requesterId = "";
    this.requesterEmpNo = this.userData.empNo;
    this.requesterName = this.userData.name;
    this.requestDept = ""; //부서코드
    this.requestDeptName = ""; //부서명
    this.requestDeptPathName = "-"; //부서명(회사 - 팀 - 누구)
    this.requestDate = "-"; // 저장날짜
    this.buyerList = []; // 구매담당자
    this.attachFileList = []; //첨부파일
    this.dates = []; // 계약기간
    this.assetTypeList = [
      // { code: assetType.GENERAL_ASSET, name:'총무자산' },
      { code: draftConst.SERVICE_AND_GENERAL_ASSET, name: "일반구매" },
      { code: draftConst.INFRA_ASSET, name: "자산구매" },
    ];

    this.currencyCode = "KRW";
    this.advanceRate = "0";
    this.advance = "0";
    this.interimRate = "0";
    this.interim = "0";
    this.balanceRate = "100";
    this.balance = "0";
    this.paymentList = [
      // { code: assetType.GENERAL_ASSET, name:'총무자산' },
      { code: "D", name: "분할지급" },
      { code: "R", name: "기성지급" },
    ];
    this.assetType = draftConst.SERVICE_AND_GENERAL_ASSET;
    this.assetTypeName = "일반구매";
    this.payment = "D";
    this.paymentName = "분할지급";

    this.originHandleDataList = []; // 구매카테고리 originlist
    this.handleDataList = []; // 구매카테고리 검색후 보여지는 리스트
    this.itemTitle = "";
    this.handleNum = "";

    this.purchasePriceList = []; // 기안금액
    this.purchaseGoodsList = []; // 구매요청품목

    this.projectCodeList = [];
    this.totalPrice = "";

    this.projectCode = "";

    this.projectName = "";
  }

  setLdapData(obj) {
    const { accountId, personName, deptCode, deptName, deptPathName, employeeNo } = obj;

    if (personName) {
      this.requesterEmpNo = employeeNo;
      this.accountId = accountId;
      this.requester = `${accountId}`;
      this.requesterName = personName;
      this.requestDept = deptCode;
      this.requestDeptName = deptName;
      this.requestDeptPathName = deptPathName;
    } else {
      this.requesterEmpNo = "";
      this.requester = "";
      this.requesterName = "";
      this.requestDept = "";
      this.requestDeptName = "";
      this.requestDeptPathName = "";
    }
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);

    const {
      assetType,
      currencyCode,
      assetTypeName,
      payment,
      paymentName,
      attachFileList,
      buyerList,
      contents,
      regDate,
      regId,
      requestDept,
      requestDeptName,
      requester,
      requesterEmpNo,
      requesterId,
      requesterName,
      status,
      statusCodeName,
      title,
      purchaseGoodsList,
      purchasePriceList,
      advanceRate,
      advance,
      interimRate,
      interim,
      balanceRate,
      balance,
      totalRequestPrice,
      contractStartDate,
      contractEndDate,
      projectCode,
      projectName,
    } = obj;
    this.assetType = assetType;
    this.assetTypeName = assetTypeName;
    this.payment = payment;
    this.paymentName = paymentName;
    this.accountId = requester || "";
    this.currencyCode = currencyCode || "";
    this.attachFileList = attachFileList || [];
    this.buyerList = buyerList || [];
    this.contents = contents || "";
    this.regId = regId || "";
    this.requestDate = regDate || "";
    this.requestDept = requestDept || "";
    this.requestDeptName = requestDeptName || "";
    this.requester = requesterId || "";
    this.requesterEmpNo = requesterEmpNo || "";
    this.requesterId = requesterId || "";
    this.requesterName = requesterName || "";
    this.status = status || "";

    this.dates = [stringToDate(contractStartDate), stringToDate(contractEndDate)] || [];

    this.advanceRate = String(advanceRate) || "0";
    this.advance = String(advance) || "0";
    this.interimRate = String(interimRate) || "0";
    this.interim = String(interim) || "0";
    this.balanceRate = String(balanceRate) || "0";
    this.balance = String(balance) || "0";
    this.statusCodeName = statusCodeName || "";
    this.title = title || "";
    if (purchasePriceList && purchasePriceList.length > 0) {
      this.purchasePriceList = purchasePriceList.map((item) => new PurchasePriceListModel(item));
    }
    if (purchaseGoodsList && purchaseGoodsList.length > 0) {
      this.purchaseGoodsList = purchaseGoodsList.map((item) => new PurchaseGoodsListModel(item));
    }
    this.totalPrice = totalRequestPrice;
    this.projectCode = projectCode;
    this.projectName = _.unescape(projectName);
  }

  getData() {
    const obj = {
      title: this.title,
      contents: this.contents,
      requesterEmpNo: this.requesterEmpNo,
      assetType: this.assetType,
      payment: this.payment,
      advanceRate: this.advanceRate,
      advance: getMoneyToNumber(this.advance),
      interimRate: this.interimRate,
      interim: getMoneyToNumber(this.interim),
      balanceRate: this.balanceRate,
      balance: getMoneyToNumber(this.balance),
      buyerList: this.buyerList,
      attachFileList: this.attachFileList,
      purchaseGoodsList: this.purchaseGoodsList,
      currencyCode: this.currencyCode,
      projectCode: this.projectCode,
      projectName: this.projectName,
    };
    if (this.dates && this.dates.length === 2) {
      obj.contractStartDate = dateToString(this.dates[0]);
      obj.contractEndDate = dateToString(this.dates[1]);
    }
    const checkTypeObj = () => {
      let retObj = {};
      // this.purchasePriceList.map((item) => {
      //   return item.getData();
      // });
      if (this.assetType === "H") {
        //용역일반구매
        // retObj = {
        //   purchasePriceList: this.purchasePriceList.map((item) => {
        //     return item.getData();
        //   }),
        // };
        retObj = {
          purchaseGoodsList: this.purchaseGoodsList.map((item, index) => {
            item.projectCode = this.projectCode;
            item.projectName = this.projectName;
            return item.getData();
          }),
          purchasePriceList: this.purchasePriceList.map((item) => {
            return item.getData();
          }),
        };
      } else {
        //인프라자산
        retObj = {
          purchaseGoodsList: this.purchaseGoodsList.map((item, index) => {
            item.projectCode = this.projectCode;
            item.projectName = this.projectName;

            return item.getData();
          }),
          purchasePriceList: this.purchasePriceList.map((item) => {
            return item.getData();
          }),
        };
      }
      return retObj;
    };

    return Object.assign({}, this.objRaw, obj, checkTypeObj());
  }

  setPriceList(itemList) {
    if (itemList && itemList.length > 0) {
      this.purchasePriceList = itemList.map((item) => new PurchasePriceListModel(item));
    } else if (!itemList) {
      this.purchasePriceList.push(new PurchasePriceListModel());
    }
  }
  setGoodsList(itemList) {
    if (itemList && itemList.length > 0) {
      this.purchaseGoodsList = itemList.map((item) => new PurchaseGoodsListModel(item));
    } else if (!itemList) {
      this.purchasePriceList.push(new PurchaseGoodsListModel());
    }
  }
  resetPurchaseList() {
    this.purchasePriceList = [new PurchasePriceListModel()];
    this.purchaseGoodsList = [new PurchaseGoodsListModel()];
  }
  // 총 기안금액
  totalRequestPrice() {
    let result = 0;
    this.purchasePriceList.forEach((item) => {
      result += getMoneyToNumber(item.requestPrice);
    });
    return addCommaForMoney(result);
  }

  // 총 품목금액
  totalGoodsPrice() {
    let result = 0;
    this.purchaseGoodsList.forEach((item) => {
      result += getMoneyToNumber(item.totalPrice);
    });
    this.totalPrice = addCommaForMoney(result);
    return addCommaForMoney(result);
  }

  // 기안금액 추가
  addPrice() {
    this.purchasePriceList.push(new PurchasePriceListModel());
  }

  // 기안금액 삭제
  removePrice(itemToRemove) {
    this.purchasePriceList = this.purchasePriceList.filter((item) => item !== itemToRemove);
  }

  // 구매요청품목 추가
  addGood() {
    this.purchaseGoodsList.push(new PurchaseGoodsListModel());
    this.purchasePriceList.push(new PurchasePriceListModel());
  }

  // 구매요청품목 삭제
  removeGood(itemToRemove) {
    const removeGoodIndex = this.purchaseGoodsList.indexOf(itemToRemove);
    this.purchaseGoodsList = this.purchaseGoodsList.filter((item) => item !== itemToRemove);
    this.purchasePriceList.splice(removeGoodIndex, 1);
  }

  // 구매요청품목 금액 업데이트 - 기안금액 적용
  updatePrice(index) {
    const totalPrice = this.purchaseGoodsList[index].totalPrice;
    this.purchasePriceList[index].requestPrice = totalPrice;
  }

  getAlertMessage() {
    if (!this.projectCode) return "프로젝트를 선택해주세요.";
    if (!this.title) return "구매요청명을 입력해주세요.";
    if (!this.contents) return "내용을 입력해주세요.";
    if (this.assetType === "H") {
      if (this.purchaseGoodsList.some((item) => !item.itemCode)) {
        return "품명을 선택해주세요.";
      }
      if (this.purchasePriceList.some((item) => item.requestPrice <= 0)) {
        return "기안금액을 입력해주세요.";
      }
      if (this.purchaseGoodsList.some((item) => item.unitType === "T" && !item.unitValue)) {
        return "단위를 입력해주세요.";
      }
      // if (this.purchasePriceList.some((item) => item.projectCode <= 0)) {

      //   return '프로젝트코드를 입력해주세요.';
      // }
    } else {
      //this.assetType === 'I';
      if (this.purchaseGoodsList.some((item) => !item.itemCode)) {
        return "품명을 선택해주세요.";
      }
      if (this.purchaseGoodsList.some((item) => !item.assetsCode)) {
        return "자산코드를 입력해주세요.";
      }
      if (this.purchasePriceList.some((item) => item.requestPrice <= 0)) {
        return "기안금액을 입력해주세요.";
      }
      if (this.purchaseGoodsList.some((item) => item.unitType === "T" && !item.unitValue)) {
        return "단위를 입력해주세요.";
      }
    }
    if (!this.requesterEmpNo) return "구매요청자를 선택해주세요.";
    if (this.buyerList.length === 0) return "구매담당자를 선택해주세요.";

    return null;
  }
}
