import { getMoneyToNumber } from "@/utils/stringUtils";

export default class PurchaseGoodsListModel {
  constructor(obj) {
    this.objRaw = {};

    this.requestCid = "";

    this.itemName = ""; // 품명
    this.itemCode = ""; // 품목코드
    this.displayCode = ""; // 품목코드 (displayCode)
    this.quantity = ""; // 수량

    this.store = ""; // 입고처
    this.note = ""; // 비고
    this.assetsCode = ""; // 자산코드
    this.standard = ""; //입고처
    this.price = ""; // 단가
    this.totalPrice = ""; // 금액
    this.unitType = ""; // 단위코드(EA, Copy, 식, 직접입력)
    this.unitValue = ""; // 단위값(직접입력 단위값)

    this.warranty = ""; // 보증기간

    this.cmdbAssetInfo = {}; // cmbd 자산정보명

    this.unitType = "E"; //단위
    this.unitValue = "EA"; //단위

    this.category = "";
    this.manufacturer = "";
    this.useYn = "";
    this.cateNum = "";
    this.category0 = "";
    this.category1 = "";
    this.category2 = "";

    if (obj) {
      this.setData(obj);
    }
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);

    Object.entries(obj).forEach(([key, value]) => {
      if (!_.isObject(value)) {
        if (!Object.prototype.hasOwnProperty.call(this, key)) {
          console.log(`%c model[${key}] empty`, "color:red");
        } else {
          if (typeof value === "number") {
            this[key] = String(value) || "";
          } else {
            this[key] = value || "";
          }
        }
      }
    });
    // obj value가 object,array 면 따로 예외처리한다
  }

  changeCalcPrice() {
    const { quantity, price } = this;

    if (!quantity && !price) return "0";

    const cal = getMoneyToNumber(quantity) * getMoneyToNumber(price);

    this.price = String(cal);
  }

  getData() {
    let obj = {
      itemName: this.itemName,
      itemCode: this.itemCode,
      displayCode: this.displayCode,
      quantity: getMoneyToNumber(this.quantity),
      manufacturer: this.manufacturer,
      assetsCode: this.assetsCode,
      totalPrice: getMoneyToNumber(this.totalPrice),
      note: this.note,
      price: getMoneyToNumber(this.price),
      unitType: this.unitType,
      unitValue: this.unitValue,
    };

    return obj;
  }

  getAlertMessage() {
    if (this.quantity === 0 || this.quantity === "0" || !this.quantity)
      return "품목의 수량을 확인해주세요.";
    if (this.price !== 0 && !this.price) return "품목의 단가를 확인해주세요.";
    //if (!this.projectCode) return '프로젝트 코드를 확인해주세요.';
    return null;
  }
}
