<template>
  <tr>
    <td class="align_right">
      {{ index + 1 }}
    </td>
    <td>
      <div class="group_form">
        <DialougeItem v-if="item.itemCode" :itemCode="item.itemCode">
          <template v-slot:dialogueHead>
            <Input
              :value.sync="item.itemName"
              :isDisabled="true"
              :maxLength="200"
              @focusout="onFocusout"
            />
          </template>
        </DialougeItem>

        <Input
          v-else
          :value.sync="item.itemName"
          :isDisabled="true"
          :maxLength="200"
          @focusout="onFocusout"
        />
        <button class="btn_search" @click="$emit('onClickSearchGoodsName')">
          <span class="ico_purchase ico_search" />
        </button>
      </div>
    </td>
    <td>
      <Input
        :value.sync="item.displayCode"
        :maxLength="200"
        :isDisabled="true"
        @focusout="onFocusout"
      />
    </td>
    <td>
      <Input
        :value.sync="item.assetsCode"
        :maxLength="200"
        :isDisabled="!assetsCode"
        @focusout="onFocusout"
      />
    </td>
    <td>
      <div class="">
        <SelectBoxQuantity :unitValue.sync="item.unitValue" :unitType.sync="item.unitType" />
      </div>
    </td>
    <td>
      <InputAutoMoney :value.sync="item.quantity" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <InputAutoMoney :value.sync="item.price" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <InputAutoMoney
        :value.sync="item.totalPrice"
        :isDisabled="true"
        :maxLength="200"
        @focusout="onFocusout"
      />
    </td>

    <td>
      <Input :value.sync="item.note" :maxLength="200" @focusout="onFocusout" />
    </td>
    <td>
      <button
        class="btn_small btn_fourthly"
        :disabled="isDisabledRemove"
        @click="() => $emit('onClickRemove')"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import DialougeItem from "@/components/common/dialouge/DialougeItem";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Input from "@/components/common/input/Input";
import { getMoneyToNumber } from "@/utils/stringUtils";
import SelectBoxQuantity from "@/components/common/selectBox/SelectBoxQuantity";

export default {
  name: "PurchasePriceInfraListLine",
  components: {
    DialougeItem,
    InputAutoMoney,
    Input,
    SelectBoxQuantity,
  },
  props: {
    model: Object,
    index: Number,
    item: Object,
    isDisabledRemove: Boolean,

    assetsCode: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    accountInput() {
      let ret = "";
      if (this.item.cmdbAssetInfo.categoryId) {
        ret = String(this.item.cmdbAssetInfo.categoryId);
      }
      return ret;
      // return this.item.accountName && this.item.accountName
      //   ? `${this.item.accountName} (${this.item.accountCode})`
      //   : '';
    },
    price() {
      const { price, quantity } = this.item;

      if (!price || !quantity) return "0";

      const nUnitPrice = getMoneyToNumber(price);
      const nAmount = getMoneyToNumber(quantity);

      const nResult = nUnitPrice * nAmount;

      const strResult = String(nResult);

      return strResult;
    },
  },
  watch: {
    price(value) {
      this.item.totalPrice = value;
      this.$emit("updatePrice", this.index);
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
