<template>
  <FilterBarBase :btnBasic="false">
    <dl>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <Input
            style="width: 240px"
            placeholder="검색어를 입력하세요"
            :value.sync="keywordInputText"
            :maxLength="200"
            @onEnterKey="onClickSearch"
          />
          <button type="submit" class="btn_medium btn_primary" @click="onClickSearch">검색</button>
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
// import axios from 'axios';
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import Input from "@/components/common/input/Input";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "ProjectCodePopupFilterBar",
  components: {
    FilterBarBase,
    CategoryGroupIncludeData,
    Input,
  },
  mixins: [FilterBarMixin],
  // props: {
  //   defaultKeywordInputText: String,
  // },
  data() {
    return {
      keywordInputText: "",
    };
  },
  methods: {
    onClickSearch() {
      const keywordInputText = this.keywordInputText.trim();
      this.$emit("onClickSearch", keywordInputText);
    },
  },
};
</script>
